import * as React from "react"

const TermsConditionsContent = () => {
    return (
		<>
      <div className="main-text-content ptb-100">
        <div className="container">
          <h3>Allmänna villkor</h3>
          <h4>Inledning</h4>
          <p>Inledning

Dessa allmänna användarvillkor (nedan kallat “Användarvillkoren”) gäller för användning av mobilapplikationen “Amal Express” (nedan kallad “Appen”), webbplatsen www.amalsend.com (nedan kallad “Webbplatsen”), produkter och tjänster som ägs och tillhandahålls av Amal Express Ekonomisk Förening med organisationsnummer 769608–9247 (nedan kallad “Amal Express” och refererad till som “vi”, “vår”, “oss”).

Den fysiska person som använder Appen, Webbplatsen eller de tjänster som Amal Express vid var tid erbjuder är nedan kallad “Användaren” och refererad till som “du”, “dig”, “din”. Amal Express och Användaren refereras gemensamt till som “Parterna” och var för sig som “Part”.

Åldersgräns: För att du ska kunna använda våra tjänster och skapa ett användarkonto, behöver du vara minst 18 år gammal. Genom att godkänna dessa villkor, intygar du att du är minst 18 år och att du har rätt att självständigt ingå detta juridiskt bindande avtal.

Godkännande av villkoren: Dessa Användarvillkor utgör ett juridiskt bindande avtal mellan dig och Amal Express. Avtalet ska anses vara ett ramavtal i enlighet med lagen (2010:751) om betaltjänster. Genom att godkänna dessa villkor, intygar du att du har tagit del av Användarvillkoren, att du accepterar villkoren i sin helhet samt att du åtar dig skyldighet att följa villkoren. Du godkänner Användarvillkoren när du skapar ett användarkonto i Appen eller Webbplatsen och i samband med detta, godkänner du även att vår behandling av dina personuppgifter sker i enlighet med vår integritetspolicy. Du kan läsa vår integritetspolicy via följande länk: https://amalsend.com/send/integritetspolicy/. De vid var tid gällande Användarvillkoren gäller under hela den tid som du har ett registrerat användarkonto och nyttjar våra tjänster. Om du inte accepterar dessa villkor, ska du inte använda våra tjänster.

 

<h4>Definitioner</h4>

Följande begrepp ska ha nedan angiven innebörd, både när de uttrycks i plural och singular:

Webbplatsen: www.amalsend.com

Tjänsten:avser vår digitala penningsöverföringstjänst som kan användas av konsumenter för att överföra pengar till andra personer genom en smarttelefon, surfplatta eller dator. Referens till ”våra tjänster” avser vår webbplats, mobilapplikation och de tjänster och produkter som vi vid var tid tillhandahåller.

Användare: Alla personer som använder våra tjänster.

Användargenererat innehåll: Alla uppgifter som Användaren registrerar i sitt användarkonto eller på annat sätt tillhandahåller till oss. Användargenererat innehåll kan även innehålla personuppgifter, såsom namn, e-postadress, telefonnummer, adress.

Tredje part: Fysisk eller juridisk person, annan än Användaren eller Amal Express.

GDPR: Europaparlamentets och rådets förordning (EU) 2016/679 av den 27 april 2016 om skydd för fysiska personer med avseende på behandling av personuppgifter och om det fria flödet av sådana uppgifter och om upphävande av direktiv 95/46/EG.

Samtliga referenser i denna integritetspolicy till begreppen “personuppgifter“, “behandling” (av personuppgifter) “registrerade“, “personuppgiftsansvarig“, “personuppgiftsbiträde“, “personuppgiftsincident” och “tillsynsmyndighet“, ska ha samma mening och innebörd som framgår av definitionslistan i artikel 4 i GDPR.

SCC: Kommissionens genomförandebeslut (EU) 2021/914 av den 4 juni 2021 om standardavtalsklausuler för överföring av personuppgifter till tredjeländer i enlighet med Europaparlamentets och rådets förordning (EU) 2016/679.

 

<h4>Information om Amal Express</h4>

Amal Express är en ekonomisk förening med säte i Göteborg, som bedriver verksamhet med penningöverföring i Sverige. Amal Express har hos Finansinspektionen (FI) genom beslut 2019-08-02 beviljats undantag från tillståndsplikt (registrerad betaltjänstleverantör) att tillhandahålla betaltjänsten penningöverföring som anges i lagen (2010:751, ändrad bl.a. 2018:175) om betaltjänster (enligt definition i lagen (2010:751) om betaltjänster, nedan kallad “Penningöverföring”). Vi tillhandahåller dock inga betalkonton enligt definition i lagen (2010:751) om betaltjänster.

Vi erbjuden en digital penningsöverföringstjänst som du kan använda för att överföra pengar till andra personer genom en smarttelefon, surfplatta eller dator (nedan kallad “Tjänsten”). Vi har som ett led i verksamheten bankavtal och samarbetsavtal med välrenommerade företaget Al Amal Express Exchange (Al Amal) i Dubai. Vi har anmält ett antal s.k. betaltjänstombud till FI som har registrerats.

Vi har en skyldighet att uppfylla de krav som ställs enligt bl.a. de grundläggande dataskyddsprinciperna och krav på rättslig grund som är tillämpliga enligt bl.a. GDPR (i Sverige även benämnd Dataskyddsförordningen) samt även vissa andra närliggande författningar inom dataskyddsområdet som ska tillämpas i relevant utsträckning.

Vi har i vår verksamhet krav på att bl.a. på ett adekvat sätt tillämpa reglerna i betaltjänstlagstiftningen och penningtvättslagstiftningen samt i övrigt uppfylla legala krav enligt GDPR samt de regler som utfärdas av tillsynsmyndigheterna Integritetsskyddsmyndigheten (IMY) och FI m.fl.

 

<h4>Kundkännedom</h4>

Innan du kan använda våra tjänster, är vi skyldiga enligt lag att genomföra olika kontroller för kundkännedom. Du åtar dig skyldighet att identifiera dig genom att tillhandahålla en kopia av en giltig identitetshandling som tillhör dig eller genom giltig eID / BankID, enligt de instruktioner som framgår i Tjänsten. Vi har rätt att genom en Tredje part, validera de dokument du tillhandahåller, innan ditt användarkonto kan bli slutligt registrerat.

Vi har rätt att neka tillhandahållandet av våra tjänster och/eller produkter om du bryter mot våra villkor, vid var tid tillämpliga lagstiftningar, domstolsbeslut, myndighetsbeslut eller beslut från annan organisation eller myndighet som vi lyder under, eller om vi anser att vi har ett berättigat intresse av att skydda oss eller för att skydda våra intressen. Vi kommer att informera dig om vi har tagit beslutet att inte tillhandahålla dig våra tjänster med information om skälet till beslutet, under förutsättning att vi inte är förhindrade enligt tillämpliga lagar eller regelverk att göra det.

 

 
 

<h4>Användarkonto</h4>

Du behöver skapa ett användarkonto för att använda våra tjänster. Du ansvarar för att ha den utrustning och programvara som krävs för att använda våra tjänster, såsom internetuppkoppling, dator, surfplatta eller smarttelefon.

Det är enbart tillåtet att skapa ett (1) användarkonto per person. Om det kommer till vår kännedom att du har registrerat flera användarkonton, har vi rätt att slå samman eller avsluta dessa användarkonton.

Du ansvarar ensamt för att skydda dina inloggningsuppgifter. Användarkontot är personligt och det är inte tillåtet att låta någon annan använda ditt användarkonto, varken direkt eller indirekt. Du ansvarar för all användning av användarkontot och all aktivitet som företas genom det registrerade användarkontot. Vid misstanke om intrång eller missbruk av användarkontot ska du omedelbart informera oss om det samt byta ditt lösenord. Vi har rätt att stänga av ditt användarkonto med omedelbar verkan. Detta gäller också om du bryter mot Användarvillkoren, inte genomför dina betalningar till oss senast på förfallodagen, begår brott eller om vi misstänker att du är inblandad i olaglig aktivitet. Du har inte rätt till ersättning eller liknande med anledning därav.

Om giltighetstiden av den identitetshandlingen som du har registrerat i användarkontot löper ut, kommer ditt användarkonto att bli automatiskt inaktiverat fram till dess att du bevisar din identitet på nytt genom en giltig identitetshandling.

När du skapar ditt användarkonto kommer du att bli ombedd att delge viss information om dig själv, såsom ditt förnamn och efternamn, e-postadress, telefonnummer m.m. Den information om dig som du registrerar ska vara fullständig, korrekt och giltig. De registrerade uppgifterna får inte strida mot tillämplig lag eller Tredje parts rättigheter och du åtar dig skyldighet att genast komplettera och/eller justera information som inte är aktuell, fullständig eller korrekt samt meddela oss om detta. Registrerade uppgifter i användares användarkonton är inte synliga offentligt eller för andra användare. Vi behandlar personuppgifter i enlighet med GDPR.

Vi kan komma att verifiera och uppdatera viss information om dig från officiella register och om information som vi får från sådana källor inte skulle vara korrekt, ska du genast meddela oss om detta för att vi ska kunna korrigera uppgifterna.

De vid var tid gällande Användarvillkoren gäller under hela den tid som du har ett registrerat användarkonto. Du har rätt att när som helst säga upp detta avtal med oss och avsluta ditt användarkonto till omedelbart upphörande genom kontoinställningarna eller genom att skicka en sådan skriftlig begäran till oss via följande e-post: info@amalsweden.com.

Du får inte använda våra tjänster i strid med tillämplig lagstiftning eller dessa Användarvillkor. Du garanterar att inte missbruka våra tjänster och att inte tillföra något kriminellt eller annat brottsligt innehåll till tjänsten. Användare som begår brott genom våra tjänster eller liknande kommer att polisanmälas och blockeras från att nyttja våra tjänster.

Du godkänner att Appens programvara automatisk installerar nya uppdateringar av Appen som du har laddat ner till din enhet, genom att godkänna dessa Användarvillkor. Om du inte godkänner sådana automatiska uppdateringar, kan du reglera detta under inställningarna i din enhet. De vid var tid gällande Användarvillkoren för Appen gäller även för uppdateringar av Appen.

 

 
 

<h4>Penningöverföring</h4>

Genom att använda våra tjänster för att genomföra en Penningöverföring, åtar du dig skyldighet att betala våra avgifter till oss vid varje enskild order av överföring som du genomför. Du kan betala för en Penningöverföring på olika sätt, bland annat genom direkt banköverföring eller med ett bank- eller kreditkort. Mer information om de betalningssätt vi accepterar framgår i Tjänsten. Du ansvarar för att ha tillräckliga likvida medel som täcker våra avgifter inklusive det önskade överföringsbeloppet, för att vi ska kunna genomföra din order avseende Penningöverföringen. Du godkänner att våra avgifter inklusive det önskade överföringsbeloppet överförs till oss, eller dras från din kortutgivare, innan vi utför Penningöverföringen.

Du får enbart använda tillåtna betalningsmedel som är kopplade till ditt användarkonto och använda tjänsten för din egen personliga räkning. När du genomför en Penningöverföring via Tjänsten, ska du följa instruktionerna som anges i samband med överföringsprocessen. Du ansvarar ensamt för att registrera och ange korrekta uppgifter i samband med Penningöverföring och vi kan inte hållas ansvariga för eventuella fel som uppstår på grund av din felaktiga registrering av uppgifter.

Du godkänner att enbart använda Tjänsten för att genomföra en Penningöverföring till en fysisk mottagare i egenskap av privatperson, som du känner. Dessutom är du skyldig att säkerställa att Penningöverföringen som mottagaren tar emot från dig, ska användas enbart för mottagarens personliga räkning samt att mottagaren tillhandahåller oss all information som vi efterfrågar för kundkännedomsinformation i samband med mottagandet av Penningöverföringen.

Det är inte tillåtet att genomföra Penningöverföring genom Tjänsten till juridiska personer (företag, organisationer, föreningar, myndigheter m.fl.), näringsidkare eller till personer som du inte känner för att exempelvis köpa tjänster eller produkter eller för donationer.

Penningöverföringen kan genomföras på olika sätt beroende på vart mottagaren befinner sig. Det kan exempelvis genomföras genom olika digitala alternativ, såsom till ett bankkonto eller en mobil plånbok, eller tillgängliggöras för kontant uthämtning hos lokala utbetalningskontor. Valet för genomförandet av Penningöverföringen och mottagarlandet kan påverka tiden för genomförandet av Tjänsten. Du ska ange den valuta som Penningöverföringen ska utföras inom.

I samband med att du godkänner betalning enligt den valda betalningslösningen i Tjänsten, godkänner du även Penningöverföringen. Vi anses ha mottagit din överföringsorder när du har godkänt Penningöverföringen ifråga. Vi kommer att skicka en skriftlig bekräftelse när vi har mottagit överföringsordern, vilket utgör ett godkännande av ditt erbjudande att köpa våra tjänster (”Beställningsbekräftelse”).

Vi anses ha ingått ett juridiskt bindande avtal om de tjänster som framgår i Beställningsbekräftelsen som vi skickar till dig, och detta gäller även om du har fått den eller inte. Tjänster som inte framgår i Beställningsbekräftelsen utgör inte en del av beställningen och vi är inte skyldiga att tillhandahålla andra tjänster än de som framgår i Beställningsbekräftelsen. Du ansvarar för att kontrollera att innehållet i Beställningsbekräftelsen är korrekt och att behålla en kopia av Beställningsbekräftelsen.

Vi har rätt, men inte skyldighet, att meddela mottagaren av Penningöverföringen om att vi mottagit din överföringsorder. När Penningöverföringen har gjorts tillgänglig för mottagaren, anses Penningöverföringen vara slutförd.

 

Vi har rätt, men ingen skyldighet, att acceptera en order avseende Penningöverföring eller annan tjänst som vi tillhandahåller. Vi har ingen skyldighet att genomföra en Penningöverföring, trots att vi har ingått eventuella tidigare avtal om sådan överföring, om:

vi inte anser att vi har tillräcklig information för att bevisa din identitet,
vi har anledning att tro att informationen du angivit gällande överföringen inte stämmer,
vi har fått felaktig eller ofullständiga uppgifter från dig,
vi inte får transaktionsinformationen som vi begär i tid, eller
kortutfärdaren nekar användningen av ditt betalkort för att betala våra avgifter för tjänsten och summan för Penningöverföringen ifråga.
Vi ansvarar inte för skador på grund av utebliven eller sen överföring av likvida medel till en mottagare, om vi nekar en Penningöverföring och inte genomför transaktionen med stöd i ovan angivna punkter.

 

<h4>Ångerrätt</h4>

Konsumenter har enligt huvudregeln i lagen (2005:59) om distansavtal och avtal utanför affärslokaler (“Distansavtalslagen”) rätt till 14 dagar ångerrätt (“Ångerfrist”). Ångerfristen börjar att löpa från den dag då avtalet ingicks. Enligt paragraf 11 i Distansavtalslagen framgår dock olika undantag som anger när ångerrätten inte gäller. I Distansavtalslagen framgår att ångerrätt inte gäller för en tjänst som har fullgjorts, om konsumenten uttryckligen har samtyckt till att tjänsten börjar utföras och gått med på att det inte finns någon ångerrätt när tjänsten har fullgjorts.

Ångerrätt gäller inte för någon specifik Penningöverföring eller annan tjänst som vi har avtalat om, som har blivit fullgjord. Genom att du accepterar dessa Användarvillkor, godkänner du att det inte finns någon ångerrätt när sådan tjänst har fullgjorts.

Om du vill åberopa ångerrätten avseende en Penningöverföring eller annan tjänst som vi avtalat om och som ännu inte blivit fullgjord, kan du kontakta oss skriftligen per post, eller via följande e-post: info@amalsweden.com. Vi kommer i samband med mottagandet av din begäran att kontrollera om ångerrätten gäller. Vid godkänd ångerrätt, kommer vi att återbetala dig de medel du har betalat för den specifika tjänsten i fråga och vi tar inte ut några avgifter.

 

<h4>Person i politiskt utsatt ställning</h4>

Vi är skyldiga att enligt lagen (2017:630) om åtgärder mot penningtvätt och finansiering av terrorism ställa frågor om du är en person i politiskt utsatt ställning (Politically Exposed Person, nedan refererat till som ”PEP”).

 

Du åtar dig härmed skyldighet att skriftligen meddela oss om du är eller har varit en person i politiskt utsatt ställning (PEP) och därmed har eller har haft en hög statlig eller politisk befattning, eller om du är en känd medarbetare och/eller familjemedlem till någon sådan person, inklusive eventuella förändringar avseende detta.

 

När du använder våra tjänster och accepterar dessa Användarvillkor, intygar och bekräftar du att Tjänsten inte kommer att användas i strid med dessa Användarvillkor, för att bruka, förfoga, inneha eller förvärva likvida medel som kommer från straffbar verksamhet eller för finansiering av terrorism, bedrägeri, penningtvätt eller andra illegala ändamål. Du intygar och bekräftar också att FN, EU, UK och/eller USA inte har riktat eller utfärdat sanktioner mot vare sig dig eller mottagaren av Penningöverföringen.

 

Vi kontrollerar regelbundet alla Användare och mottagare av Penningöverföring mot vid var tid gällande PEP- och sanktionslistor genom en Tredje part som vi anlitar för detta ändamål, med syfte att säkerställa säkerheten för de likvida medel som blir överförda genom användning av Tjänsten. Detta kontrollsystem utgör en digitalt inbyggd funktion i Tjänsten, och kontrollerna sker per automatik. Vi genomför även bevakning av samtliga Penningöverföringar enligt våra interna rutiner, för att bland annat identifiera samt upptäcka transaktioner eller beteendemönster av udda eller misstänksam karaktär.

 

Vi behöver kontrollera samt validera den information som du tillhandahåller till oss, och i vissa fall behöver vi komplettera sådan information. Du godkänner härmed att vi, eller av oss anlitad Tredje part, genomför de kontroller, efterforskningar och bevakningar som vi anser vara nödvändiga för att vi ska kunna göra detta.

 

Om du inte besvarar på en informationsförfrågan, kan vi återkalla, stoppa eller vägra Penningöverföringen samt begränsa ditt användarkonto genom att exempelvis stänga av din tillgång till kontot.

 

<h4>Avgifter, skatter och växelkurser</h4>

Vid var tid aktuella avgifter och växelkurser framgår i Tjänsten och presenteras innan du kan godkänna en Penningöverföring. Det är vi som väljer vid var tid gällande växelkurs för Tjänsten, som vi baserar på marknadskurs. Vi kan göra ett påslag på växelkursen, erhålla en vinst på påslaget samt justera växelkursen från tid till annan efter eget beslut och behöver inte meddela Användare om sådana prisjusteringar.

Betalningen för tjänsten debiteras från ditt angivna betalkonto, bank- eller kreditkort i samband med att du bekräftar överföringsordern.

Du ansvarar för eventuella skatter, avgifter eller andra kostnader som debiteras av Tredje part med anledning av en Penningöverföring, såsom exempelvis kortavgifter och avgifter för internet, data- eller telekommunikation.

 

<h4>Användarens godkännanden</h4>

Språk: För kommunikation mellan dig och Amal Express, kan följande språk användas: svenska, engelska, somaliska.

Meddelanden: Du godkänner härmed att vi har rätt kontakta dig genom att skicka skriftliga meddelanden via e-post eller SMS till den e-postadress respektive det telefonnummer som du har registrerat i ditt användarkonto. Du anses ha mottagit meddelandena samma dag som de skickats till dig. I vissa fall kan du även se meddelanden i ditt användarkonto. Om du vill skicka skriftliga meddelanden till Amal Express, kan det ske till följande e-post: info@amalsweden.com, eller per post till följande adress: Box 76, 424 22 Angered.

Myndigheter: Du godkänner och bekräftar härmed att vi kan dela uppgifter och information om dig inklusive information om vilka av våra tjänster och/eller produkter som ny nyttjar, till statliga myndigheter om vi är skyldiga att göra det enligt tillämplig lag, eller om vi anser att det kan förhindra eller utreda penningtvätt, finansiering av terrorism, bedrägeri eller andra brott.

Marknadsföring: Du godkänner härmed att vi har rätt att delge reklam/erbjudanden från oss eller våra anslutna samarbetspartners till dig genom våra tjänster, baserat på ditt beteendemönster och den information som du registrerar i våra tjänster eller som vi har fått genom förfrågan. Vi har rätt att delge avidentifierad information om dig till anslutna samarbetspartners för reklam och marknadsföringssyften av samarbetspartnernas produkter/tjänster som vi anser är relevanta för dig. Vi delar dock aldrig några direkta personuppgifter hänförliga till dig till sådana samarbetspartners, utan ditt föregående uttryckliga samtycke.

Externa länkar: Vi har ingen kontroll över externa länkar till andra webbplatser som kan förekomma i våra tjänster och ansvarar inte för innehåll, reklam, produkter, tjänster eller annat innehåll som är tillgänglig via sådana webbplatser. Vi är varken direkt eller indirekt ansvarigt för skada eller förlust som orsakas av eller i samband med användning av sådant innehåll, varor eller tjänster som är tillgängliga på eller via externa webbplatser. Vi ansvarar inte heller för innehållet i externa länkar som leder till Tredje parter, som förekommer i våra tjänster. Du är ensamt ansvarig för att ta reda på hur sådan Tredje part behandlar dina personuppgifter i samband med att du besöker Tredje parts digitala plattformar, när du kontaktar Tredje parter eller när ni ingår ett avtal med varandra. Vi är inte i något fall ansvariga för förlust eller skada som orsakas dig eller någon annan, på grund av orsak hänförlig till Tredje part, oavsett om du blivit hänvisad till sådan Tredje part genom externa länkar i våra tjänster eller på annat sätt.

Avtal med Tredje part: Om du ansluter dig till tredjepartstjänster, beställer, bokar eller köper något från Tredje part, gäller sådan Tredje parts villkor. Vi har inget juridiskt ansvar för avtal som ingås mellan dig och Tredje part, eller för tjänster som utförs av Tredje part.

Underleverantörer: Vi anlitar underleverantörer i rollen underbiträden, för att de ska behandla personuppgifter för vår räkning, för att vu ska kunna tillhandahålla våra tjänster, förbättra våra tjänster och för att vi ska uppfylla våra avtalsenliga och rättsliga förpliktelser. Vi ingår personuppgiftsbiträdesavtal med samtliga underbiträden när det krävs, med i enlighet med bestämmelserna i GDPR. Vi kan lämna ut personuppgifter till Tredje part, för att fullgöra förpliktelser enligt avtal, lag, krav från myndigheter, för att tillvarata rättsliga intressen eller för att upptäcka och förebygga tekniska-, drifts- eller säkerhetsproblem. Exempel på underbiträden som vi anlitar är IT-, hosting- och serverleverantör, affärssystem, e-postleverantör, redovisningskonsult m.fl. Genom att godkänna dessa Användarvillkor, eller genom att kontakta oss, ger du oss ett allmänt förhandstillstånd och samtycke till att vi anlitar underbiträden på så sätt om beskrivits ovan samt i enlighet med beskrivningen i vår integritetspolicy.

 

<h4>Reklamation</h4>

Vi ansvarar enbart för tjänster som vi tillhandahåller och/eller utför åt dig och eventuella klagomål, reklamationer eller liknande avseende sådana ska meddelas direkt till oss. Reklamation kan ske via våra tjänster eller genom att skicka ett meddelande till oss via följande e-post: info@amalsweden.com

Vi ansvarar inte för fel eller brist som hänför sig till tjänst som utförts av Tredje part eller som på annat sätt orsakats på grund av Tredje part, såvida vi inte agerat grovt vårdslöst.

 

 
 

<h4>Immateriella rättigheter</h4>

Du intygar härmed att du förstår och accepterar att innehållet i våra tjänster kan innehålla immateriella rättigheter som ägs av oss eller våra licensgivare. Du samtycker härmed till att respektera samtliga immateriella rättigheter, inklusive men inte begränsat till upphovsrätt, varumärke och firmanamn (oaktat om det registrerats eller ej) innefattat eller uppvisat i våra tjänster eller som du fått tillgång till i samband med användning av våra tjänster.

 

<h4>Ansvar, befrielsegrunder, friskrivning m.m.</h4>

Genom att använda våra tjänster, samtycker du till att vi inte gör några utfästelser eller lämnar några garantier av något slag, varken uttryckliga eller underförstådda.

Vi ansvarar inte för skador eller förseningar som orsakats på grund av fel eller brist i internet-, tele- eller elförsörjningsförbindelse eller Tredje parts tekniska utrustning, system, tjänster, produkter eller liknande som vi inte har någon direkt kontroll över. Vi ansvarar inte heller för förlust av data eller förseningar avseende Penningöverföring som orsakats av användningen av Tredje parts produkter och/eller tjänster. Detta innebär bland annat att vi inte ansvarar för kortutgivares tjänster eller virus som härrör från Tredje part.

Vi friskriver oss härmed, i den utsträckning det är tillåtet enligt tillämplig lag, från allt ansvar för samtliga direkta och indirekta skador och/eller förluster, exempelvis men ej begränsat till, utebliven vinst, inkomstförlust eller andra skador som uppstått för dig eller för någon Tredje part i samband med användandet av eller hinder vid användandet av våra tjänster, oavsett hur skadan uppstår. Denna ansvarsbegränsning utesluter eller begränsar inte ansvar i sådan utsträckning som inte är tillåtet enligt tillämplig lag.

Part är befriad från sina åtaganden enligt dessa Användarvillkor, ersättningsskyldighet och annat ansvar för skada, om fullgörande av skyldighet omöjliggörs eller försvåras utanför Parts kontroll, såsom på grund av exempelvis, men inte uteslutande: epidemi, pandemi, myndighetsbeslut, strejk, eldsvåda, krig, naturkatastrofer eller andra omständigheter som ligger utanför Parts kontroll och vars följder Part inte skäligen kunnat undvika eller övervinna. Detsamma gäller om underleverantör till Part drabbas av hinder som avses i denna bestämmelse. När hindret upphör, ska förpliktelserna fullgöras på avtalat sätt i den mån det är möjligt.

 

<h4>Support och underhåll</h4>

Våra tjänster uppdateras löpande och det lämnas inga garantier om att de kommer att vara fria från diverse störningar eller andra tekniska komplikationer. Vi ska, efter bästa förmåga och inom skälig tid, om möjligt, avhjälpa eventuella anmälda fel eller brister.

Vi har rätt att stänga av tillgången till våra tjänster för tekniskt underhåll, service, uppdateringar, för att skydda våra tjänster från obehöriga angrepp eller för att vidta andra åtgärder som är påkallade av tekniska, drifts- eller säkerhetsmässiga skäl. Vi behöver inte meddela Användare när sådana avbrott kan komma att äga rum.

Bristande tillgång till våra tjänster eller om en Penningöverföring inte kan genomföras på grund av ovan angivna åtgärder, fel eller liknande ger inte Användare rätt till skadestånd, återbetalning, avdrag eller annan ersättning, såvida det inte framgår annat av tvingande lagstiftning.

<h4>Statistik och anonymiserad data</h4>

Vi har rätt att använda och anonymisera all data som finns i eller som genererats av våra tjänster, för bland annat statistiska och utvärderande ändamål och för utveckling av våra tjänster. All äganderätt till anonymiserad data tillfaller oss, och vi har rätt att bruka och lagra sådan data utan begränsning i tiden.

 

<h4>Cookies</h4>

Vår webbplats använder cookies och genom dessa kan vi få information om besökares användning av Webbplatsen, lokaliseringsuppgifter och annan användarinformation som tillhandahålls via bland annat webbanalys- och/eller trafikmätningsleverantör (tredjepartscookies). Mer information om hur Webbplatsen använder cookies finns att läsa i vår cookiepolicy: https://amalsend.com/send/integritetspolicy/.

 

<h4>Ändringar</h4>

Vi har rätt att när som helst, utan föregående meddelande därom, fritt ändra våra tjänsters design, funktioner, innehåll och liknande, samt rätt att förändra vårt utbud av tjänster, erbjuda nya tjänster, tilläggstjänster samt rätt att upphöra med tillhandahållandet av våra tjänster.

Vi har rätt att när som helst uppdatera och ändra Användarvillkoren vid behov, exempelvis för att förtydliga innehållet, vid erbjudande av nya tjänster eller tilläggstjänster, om det är påkallat på grund av lagändring, myndighetsbeslut, förordningar eller avser att skydda Parterna, våra partners eller om det är påkallat av tekniska-, drifts- eller säkerhetsmässiga skäl. Eventuella ändringar träder i kraft i samband med att ändringarna har blivit publicerade i tjänsterna, såvida inte ändringarna väsentligen påverkar dina rättigheter eller i övrigt kräver ditt samtycke.

Om ändringarna väsentligen påverkar dina rättigheter eller i övrigt kräver ditt samtycke, träder sådana villkorsändringarna ikraft trettio (30) dagar efter att de har kommunicerats till dig. Om du inte godkänner villkoren kan du välja att avsluta ditt användarkonto och upphöra med användningen av våra tjänster innan ändringarna träder i kraft. Om du inte avslutar ditt användarkonto innan ändringarna träder ikraft, innebär det att du godkänner de uppdaterade villkoren.

 

<h4>Övriga bestämmelser</h4>

<h5>Felskrivningar:</h5> Vi reserverar oss för eventuella felskrivningar som kan förekomma i våra tjänster och har rätt att åtgärda upptäckta fel utan föregående meddelande om det.

<h5>Bilder:</h5> Bilder som förekommer i våra tjänster har ett illustrativt syfte.

<h5>Ogiltig bestämmelse:</h5> Om skiljenämnd eller domstol skulle finna att en bestämmelse i dessa Användarvillkor skulle befinnas ogiltig, ska detta inte innebära att villkoren i dess helhet är ogiltigt. Istället ska nödvändiga justeringar av Användarvillkoren ske, för att vidmakthålla villkorens syfte, struktur och anda.

<h5>Överlåtelse:</h5> Du får inte överlåta eller överföra några rättigheter eller skyldigheter som framgår av dessa Användarvillkor. Vi har rätt att överlåta och överföra rättigheter eller skyldigheter som framgår av dessa Användarvillkor, exempelvis i samband med överlåtelse av rörelsen.

 

<h4>Tillämplig lag och tvister</h4>

<h5>Tillämplig lag:</h5> Dessa Användarvillkor ska tolkas enligt svensk lag och rätt.

<h5>Alternativ tvistelösningsmetod:</h5> Tvister som uppstår mellan Användare i egenskap av konsument och Amal Express ska i första hand försöka lösas mellan Parterna. Konsumenter har alltid rätt att vända sig till Allmänna reklamationsnämnden (ARN), som erbjuder en alternativ tvistlösning vid konsumenttvister (mer information finns att läsa via www.arn.se). Dessutom kan Konsumenter lämna klagomål online genom EU-kommissionens digitala plattform för medling i tvister via följande länk: http://ec.europa.eu/consumers/odr. Klagomål som lämnas via EU-kommissionens digitala plattform kommer automatiskt att bli vidarebefordrat till rätt nationellt tvistlösningsorgan, som därefter försöker lösa tvisten tillsammans med Parterna utanför domstol genom medling.

<h5>Allmän domstol:</h5> Om Amal Express och Användaren inte kommer fram till en lösning av tvisten genom någon alternativ tvistelösningsmetod, kan tvisten bli prövad och slutligt avgjord av svensk allmän domstol i Göteborg med tingsrätten som första instans, om inte annat följer av tvingande lag.</p>
        </div>
      </div>
		</>
    );
}

export default TermsConditionsContent;