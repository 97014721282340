import * as React from "react"

const Footer = () => {
  return (
    <>
      <div className="copy-right-area bg-color-f8f8f8">
        <div className="container">
          <p>
            © amalsend{" "}
           
          </p>
        </div>
      </div>
    </>
  )
}

export default Footer
